@import 'variables';

nav.main li a {
  margin-left: 0px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    border-bottom: 10px solid $blue;
    padding-bottom: 14px;
    margin-bottom: -20px;
    //width: calc(100%+500px);
  }
}

nav.subnav li a {
  margin-right: -1px;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    border-top: 10px solid $blue;
    padding-top: 14px;
    margin-top: -20px;
  }
}

.index-hero {
  color: #222222;
  font-family: "Bebas Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 0.8em;
  margin: 1.2em auto 0.5em auto;
  width: 4.5em;
  font-size: 3.0em;
  padding-left: 0.4em;
  @media (min-width: $tablet) {
    margin: 1em auto 0.5em auto;
    font-size: 13vmin;
  }

}

.mission {
  font-size: 1.0em;
  @media (min-width: $tablet) {
    font-size: 3.5vmin;
  }
}

.intro {
  font-size: 1.1em;
  margin-top: 1em;
  text-align: justify;
  padding: 0 1em;
  @media (min-width: $tablet) {
    margin-top: 2em;
    font-size: 20pt;
    padding: 0 1em;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0.5em 0 0.5em;
}

@media(max-width: $tablet) {
  h1 {font-size: 2.7em;}
  h2 {font-size: 2.3em;}
  h3 {font-size: 2em;}
}

h1.hero {
  font-size: 3em;
  margin: 1em 0 0.2em;
  @media(min-width: $tablet) {
    font-size: 6.5em;
    margin: 0.4em 0 0.2em;
  }
}

h2.hero {
  font-size: 2.5em;
  margin: 1em 0 0.2em;
  line-height: 0.9;
  @media(min-width: $tablet) {
    font-size: 4.5em;
    margin: 3.5rem 0 1rem;
  }
}

.upcase {
    font-size: 1.2em;
}